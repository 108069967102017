<template>
  <v-container>
    <v-row class="title-sticky">
      <v-col cols="12" lg="12">
        <TitleSection :title="title[lang]" btnBack backHome></TitleSection>
      </v-col>
    </v-row>

    <template v-if="user">
      <v-row>
        <v-col cols="12" lg="12" class="pb-0">
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>

              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ type }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="12" class="pt-0">
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              color="primary"
              v-model="value"
              :weekdays="weekday"
              :type="type"
              :events="myTickets"
              :event-overlap-mode="mode"
              :event-color="getEventColor"
              @click:more="viewDay"
              @click:date="viewDay"
              @click:event="showEvent"
            ></v-calendar>

            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-toolbar-title
                    v-html="`#${selectedEvent.name}`"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="tertiary" @click="selectedOpen = false">
                    {{ btnClose[lang] }}
                  </v-btn>
                  <v-btn
                    text
                    color="tertiary"
                    :to="{
                      name: 'TicketDetail',
                      params: { purchaseId: selectedEvent.IDCOMPRA, parkId: selectedEvent.IDPARQUE },
                    }"
                    >{{ btnView[lang] }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <div class="text-center my-10 text-medium fw-400">
        {{ noUserMsg[lang] }}
      </div>
    </template>
  </v-container>
</template>

<script>
import TitleSection from "@/components/titles/TitleSection";
import { mapGetters } from "vuex";
export default {
  components: {
    TitleSection,
  },
  data: () => ({
    title: {
      en: "My calendar",
      es: "Mi calendario",
    },
    noUserMsg: {
      es: "Necesitas iniciar sesión para ver tu calendario",
      en: "You need to log in to see your calendar",
    },
    typeToLabel: {
      en: "Type",
      es: "Tipo",
    },
    btnClose: {
      en: "Cancel",
      es: "Cerrar",
    },
    btnView: {
      es: "Ver detalles",
      en: "See details",
    },
    //
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    allTickets: [],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    colors: ['#444444', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
  }),
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      user: "getSessionUser",
      shoppingList: "getMyShoppingsList",
    }),
    myTickets() {
      if (!this.shoppingList) return [];
      const tickets = [
        ...this.shoppingList?.filter(item => item.FECHAVISITA && item.FECHAVISITA != 'Pendiente')?.map((item) => {
          console.log("FECHA", this.formatDateCalendar(item.FECHAVISITA));

          return {
            ...item,
            name: String(item.DESCRIPCION_CATEGORIA),
            start: new Date(
              `${this.formatDateCalendar(item.FECHAVISITA)}T00:00:00`
            ),
            end: new Date(
              `${this.formatDateCalendar(item.FECHAVISITA)}T23:59:59`
            ),
            color: this.setEventColor(item.ESTADO),
            timed: true,
          };
        }),
      ];
      console.log(tickets);
      return tickets;
    },
  },
  created() {
    this.setToday()
  },
  methods: {
    setToday() {
      this.value = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setEventColor(status) {
      if (status == 4) return "#af0000";
      if (status == 1) return "orange";
      if (status == 2) return "#00a39c";
      return "tertiary";
    },
    getEventColor(event) {
      return event.color;
    },
    formatDateCalendar(date) {
      const arr = date.split("/");
      const dateFormatted = `${arr[2]}-${arr[1]}-${arr[0]}`
      return dateFormatted;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style>
</style>